/**
 * @author anthon.fredriksson@svenskaspel.se (Anthon Fredriksson)
 * @author mathias.sandberg@svenskaspel.se (Mathias Sandberg)
 */
'use strict';
import PropTypes from 'prop-types';
import './../stylesheets/button-bet.less';

const ButtonBet = React.forwardRef((
  {
    align,
    branding,
    size,
    selected,
    className,
    result,
    label,
    labelStyle,
    value,
    animated,
    autotestId,
    children,
    system,
    trending,
    textAlign,
    fixedWidth,
    ...props
  },
  ref
) => {
  let classNames = ['btn-bet', `btn-bet-${branding}`, `btn-bet-${size}`];

  selected && classNames.push('btn-bet-selected');
  animated && classNames.push('btn-bet-animated');
  result && classNames.push('btn-bet-result');
  align && classNames.push(`btn-bet-align-${align}`);
  trending && classNames.push(`btn-bet-trending-${trending}`);
  fixedWidth && classNames.push('btn-bet-fixed-width');
  textAlign && classNames.push(`btn-bet-text-align-${textAlign}`);

  if (className) {
    classNames = [...classNames, ...className.split(' ')];
  }
  // Remove potential duplicates
  classNames = classNames.filter((className, index) => classNames.indexOf(className) === index);

  return (
  // eslint-disable-next-line react/button-has-type
    <button
      className={classNames.join(' ')}
      id={autotestId}
      ref={ref}
      {...props}
    >
      {label ? (
        <span className="btn-bet-label" style={labelStyle}>
          {label}
        </span>
        ) : null}
      {system ? (
        <span className="btn-bet-label-system">
          {system}
        </span>
        ) : null}
      {value ? (
        <span className="btn-bet-value">
          {value}
        </span>
        ) : null}
      {children}
    </button>
  );
});

ButtonBet.defaultProps = {
  branding: 'default',
  className: '',
  disabled: false,
  onClick: () => {},
  selected: false,
  size: '300',
  type: 'button'
};

ButtonBet.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  animated: PropTypes.bool,
  autotestId: PropTypes.string,
  branding: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  onClick: PropTypes.func,
  result: PropTypes.bool,
  selected: PropTypes.bool,
  size: PropTypes.string,
  system: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  trending: PropTypes.oneOf(['negative', 'positive']),
  type: PropTypes.oneOf(['submit', 'button']),
  value: PropTypes.string
};

export default ButtonBet;
